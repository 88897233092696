body {
  color: #333;
  background: #f7f7f7;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  padding-bottom: 80px;
}
h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: #333;
}
a {
  color: #61933A;
}
textarea {
  resize: none;
  border: 1px solid #CCCCCC;
  outline: #CCCCCC;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
.center {
  display: block;
  text-align: center;
}
.container {
  padding: 0 30px;
  /*max-width: 450px;*/
  /*margin: 0 auto;*/
}
.logo {
  text-align: center;
  margin: 20px 0 20px;
}
.logo img {
  width: 120px;
}

nav {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: #f7f7f7;
  border-top: 1px solid #E7E7E7;
  height: 70px;
  z-index: 999;
}
nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-around;
  height: 100%;
  align-items: center;
}
nav ul li {
  padding: 0;
  margin: 0;
}
nav ul li.active svg path {
  fill: #61933A;
}
.card {
  background: #FFFFFF;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 30px;
}
.card.login {
  margin-top: 80px;
}
p.card-title {
  margin-top: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.form-row {
  margin-bottom: 20px;
}
.form-row label {
  display: block;
  font-size: 12px;
  color: #333;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 6px;
}
.form-row input {
  width: 100%;
  background: #FFFFFF;
  height: 40px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 5px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}
.form-row input:focus {
  outline-color: #61933A;
}
.form-row button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 0;
  background: #61933A;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  margin: 10px 0;
  cursor: pointer;
}
.form-error-message {
  display: none;
  margin-top: 5px;
  font-size: 12px;
  color: #D60000;
}
.form-row.has-error .form-error-message {
  display: block;
}

.login-error {
  color: #D60000;
  margin-bottom: 35px;
  display: flex;
  align-items: center;
}
.login-error span {
  margin: 0 auto;
}
.cursor {
  cursor: pointer;
}

.add-button {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #61933A;
  box-shadow: 0 15px 40px rgba(97, 147, 58, 0.35);
  position: fixed;
  bottom: 100px;
  right: 30px;
}

@media (min-width: 400px) {
    .container {
      padding: 0 30px;
    }
  }
@media (min-width: 768px) and (min-height: 1024px) {
    .logo img {
      width: 200px;
    }
  }
